import Head from 'next/head'
import SectionContainer from './SectionContainer'
import styles from './Section.module.scss'
import classNames from 'classnames'
import { maxBreakpoints } from 'constants/viewport'
import { Image } from 'components/Image'

const bgBlue = 'blue'
const bgDefault = 'bgDefault'
const bgPrimaryHighlightBlue = 'bgPrimaryHighlightBlue'
const bgShadowPrimaryHighlightBlue = 'bgShadowPrimaryHighlightBlue'
const bgShadowPrimaryHighlightWhiteBlue = 'bgShadowPrimaryHighlightWhiteBlue'
const blueGradient1 = 'blueGradient1'
const neutralGrey8 = 'neutralGrey8'
const purpleBlueGradient1 = 'purpleBlueGradient1'
const white = 'white'
const achieveHighlightBlue = 'achieveHighlightBlue'
const bgBlue100 = 'blue100'

export const SECTION_VARIANTS = {
  bgBlue,
  bgPrimaryHighlightBlue,
  bgDefault,
  bgShadowPrimaryHighlightBlue,
  bgShadowPrimaryHighlightWhiteBlue,
  blueGradient1,
  purpleBlueGradient1,
  neutralGrey8,
  white,
  achieveHighlightBlue,
  bgBlue100,
}

function Section({
  children,
  defaultPadY = false,
  backgroundColor,
  backgroundImageAlt = '',
  mediaContent,
  middleMediaContent,
  mobileMediaContent,
  backgroundObjectPosition = 'top',
  childContainerClassName,
  className,
  id,
  sectionStyles,
  showPattern = false,
  contain = true,
  variant = bgDefault,
  preloadBackground = false,
  ...restProps
}) {
  const hasBg = Boolean(mediaContent || middleMediaContent || mobileMediaContent)

  const mobileMediaUrl = mobileMediaContent?.url ? `${mobileMediaContent?.url}?fm=webp&q=50` : null

  const middleMediaUrl = middleMediaContent?.url ? `${middleMediaContent?.url}?fm=webp&q=50` : null

  const mediaUrl = mediaContent?.url ? `${mediaContent?.url}?fm=webp&q=50` : null

  const contents = contain ? (
    <SectionContainer className={childContainerClassName}>{children}</SectionContainer>
  ) : (
    children
  )

  return (
    <section
      id={id}
      style={{
        // TODO: lets remove explicit backgroundColor and just allow a style object to be passed
        // leaving the responsibility of custom css to be accurately verified by CMS user.
        backgroundColor,
        ...sectionStyles,
      }}
      className={classNames(styles['achieve-section'], className)}
      data-variant={variant}
      data-default-pad-y={defaultPadY}
      {...restProps}
    >
      {preloadBackground && hasBg && (
        <Head>
          {mobileMediaUrl && (
            <link
              rel="preload"
              href={mobileMediaUrl}
              as="image"
              media={`(max-width: ${maxBreakpoints.md - 1}px)`}
            />
          )}
          {middleMediaUrl && (
            <link
              rel="preload"
              href={middleMediaUrl}
              as="image"
              media={`(min-width: ${maxBreakpoints.md}px) and (max-width: ${
                maxBreakpoints.lg - 1
              }px)`}
            />
          )}
          {mediaUrl && (
            <link
              rel="preload"
              href={mediaUrl}
              as="image"
              media={`(min-width: ${maxBreakpoints.lg}px)`}
            />
          )}
        </Head>
      )}

      {showPattern && (
        <span className={styles['achieve-pattern-container']}>
          <Image
            src="/a-pattern.svg"
            alt="Achieve pattern"
            layout="fill"
            objectFit="cover"
            objectPosition={backgroundObjectPosition}
            className={styles['achieve-pattern']}
          />
        </span>
      )}
      {/* Only display the a full background image when present and background pattern is disabled */}
      {!showPattern && hasBg && (
        <>
          <div
            role="img"
            aria-label={backgroundImageAlt}
            className={classNames(
              styles['achieve-section-bg-img'],
              styles['achieve-section-bg-img-sm']
            )}
            style={{
              backgroundImage: `url(${mobileMediaUrl})`,
              backgroundPosition: backgroundObjectPosition,
            }}
          />
          <div
            role="img"
            aria-label={backgroundImageAlt}
            className={classNames(
              styles['achieve-section-bg-img'],
              styles['achieve-section-bg-img-md']
            )}
            style={{
              backgroundImage: `url(${middleMediaUrl})`,
              backgroundPosition: backgroundObjectPosition,
            }}
          />
          <div
            role="img"
            aria-label={backgroundImageAlt}
            className={classNames(
              styles['achieve-section-bg-img'],
              styles['achieve-section-bg-img-lg']
            )}
            style={{
              backgroundImage: `url(${mediaUrl})`,
              backgroundPosition: backgroundObjectPosition,
            }}
          />
        </>
      )}
      {contents}
    </section>
  )
}

export default Section
