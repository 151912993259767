import { pickAll } from 'ramda'

export const contentMap = (content = [], requestedFields = [], withFieldsProp = true) => {
  let fieldMap
  if (Array.isArray(content) && withFieldsProp) {
    fieldMap = content.reduce((accum, { fields }) => {
      if (!fields) return accum
      const name = fields?.fieldName ? fields.fieldName : Object.keys(fields)
      if (name) {
        accum[name] = fields
      }
      return accum
    }, {})
  } else if (Array.isArray(content) && !withFieldsProp) {
    fieldMap = content.reduce((accum, item) => {
      if (!item) return accum
      const name = item?.fieldName ? item.fieldName : Object.keys(item)
      if (name) {
        accum[name] = item
      }
      return accum
    }, {})
  } else {
    Object?.keys(content)?.forEach((key) => {
      if (!requestedFields.includes(key)) {
        delete content[key]
      }
    })
    fieldMap = content
  }
  return pickAll(requestedFields, fieldMap)
}
